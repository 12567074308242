@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root {
  --primaryFont: "Work Sans", sans-serif;
}
#root {
  min-height: 100vh;
}

body {
  background: #1c1e22;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 320px) {
  body {
    min-width: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}

.container {
  /* max-width: 1536px; */
  margin: 0 auto;
  width: 100%;
}
.highlightedText {
  position: relative;
  color: transparent;
  text-shadow: 0 0 10px #c44a85;
  padding: 5px;
}

.textContainer {
  background-color: #c44a85;
  padding: 10px;
  display: inline-block;
}
