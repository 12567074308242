.footer {
  background: #1c1e22;
  padding: 20px 0;

  width: 100%;
  z-index: 5;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 18px;
}
.logoAndList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}
.logoContainer,
.socialContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.7);

  height: 101px;
  padding: 0 20px;
}
.socialContainer {
  gap: 13px;
  background: rgba(255, 255, 255, 0.9);
  padding: 0 25px;
  margin-left: auto;
}
.logo {
  width: 92px;
  height: 90px;
}
.logoText {
  color: var(--White, #fff);
  text-align: center;
  font-family: Alumni Sans Collegiate One;
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 120px */
  text-transform: uppercase;
}
.twitterIcon {
  font-size: 60px;
  color: #000;
}
.telegramContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #229ed9;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.telegramIcon {
  font-size: 40px;

  border-radius: 50%;

  color: #fff;
  margin-right: 2px;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.list {
  color: var(--White, #fff);
  font-family: var(--primaryFont);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 36px */
  letter-spacing: -1px;
  white-space: nowrap;
}
.copyRight {
  color: #fff;
  text-align: center;
  font-family: var(--primaryFont);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  padding: 10px 0;
  letter-spacing: -1px;
}
@media only screen and (max-width: 640px) {
  .container {
    flex-direction: column;
  }
  .logoContainer {
    display: none;
  }
  .container {
    padding-left: 5%;
  }
  .list {
    font-size: 20px;
  }
}
